import {
  type SanityBlock,
  type SanityRestaurantMenuBlock,
} from '@data/sanity/queries/types/blocks'
import {
  type SanityGridColumn,
  type SanityGridModule,
} from '@data/sanity/queries/types/modules'
import {
  type SanityHomePage,
  type SanityPage,
} from '@data/sanity/queries/types/page'
import {
  type MenuItemSchema,
  type MenuSchema,
  type MenuSectionSchema,
} from './types'
import { type Locale } from '@lib/language'

type RestaurantMenuBlock = SanityRestaurantMenuBlock | undefined

const isRestaurantMenuBlock = (block: SanityBlock) =>
  block._type === 'restaurantMenu'

const isRestaurantMenuColumn = (column: SanityGridColumn) =>
  column.blocks.some(isRestaurantMenuBlock)

export const getMenuSchema = (
  locale: Locale,
  page: SanityPage | SanityHomePage,
  currencyCode?: string
) => {
  const gridWithRestaurantMenuModule = page.modules?.find(
    (module) =>
      module._type == 'grid' && module.columns?.some(isRestaurantMenuColumn)
  ) as SanityGridModule | undefined

  if (!gridWithRestaurantMenuModule) {
    return
  }

  const restaurantMenuBlock = gridWithRestaurantMenuModule.columns
    ?.find(isRestaurantMenuColumn)
    ?.blocks?.find(isRestaurantMenuBlock) as RestaurantMenuBlock
  const menuSchema: MenuSchema = {
    '@type': 'Menu',
    inLanguage: locale,
    hasMenuSection: restaurantMenuBlock?.categories?.map((category) => {
      const menuSectionSchema: MenuSectionSchema = {
        '@type': 'MenuSection',
        name: category?.name,
        hasMenuItem: category.items?.map((item) => {
          const menuItemSchema: MenuItemSchema = {
            '@type': 'MenuItem',
            name: item.title,
          }

          if (item.description) {
            menuItemSchema.description = item.description
          }

          if (item.price && currencyCode) {
            menuItemSchema.offers = {
              '@type': 'Offer',
              price: `${item.price}`,
              priceCurrency: currencyCode,
            }
          }

          return menuItemSchema
        }),
      }

      return menuSectionSchema
    }),
  }

  return JSON.stringify(menuSchema)
}
