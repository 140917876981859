import {
  type SanityDayOfWeek,
  type SanitySchemaSettingsOrganization,
} from '@data/sanity/queries/types/site'
import { type PostalAddressSchema, type RestaurantSchema } from './types'
import { getSanityImageUrl } from '@lib/image'
import { type Locale } from '@lib/language'
import { getPageUrl } from '@lib/routes'

const dayOfWeekNames: Record<SanityDayOfWeek, string> = {
  Mo: 'Monday',
  Tu: 'Tuesday',
  We: 'Wednesday',
  Th: 'Thursday',
  Fr: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday',
}

const getAcceptsReservationsValue = (acceptsReservations: string) => {
  if (acceptsReservations === 'Yes') {
    return 'True'
  }

  if (acceptsReservations === 'No') {
    return 'False'
  }

  return acceptsReservations
}

export const getOrganizationSchema = (
  locale: Locale,
  siteUrl: string,
  organization?: SanitySchemaSettingsOrganization
) => {
  const imageUrl = getSanityImageUrl(organization?.logo)
  const restaurantSchema: RestaurantSchema = {
    '@type': 'Restaurant',
    name: organization?.name,
    url: organization?.url,
    logo: imageUrl,
    image: imageUrl,
    telephone: organization?.phone,
    email: organization?.email,
    sameAs: organization?.sameAsUrls,
  }
  const addresses = organization?.addresses
    ?.filter((address) => !!address.country)
    ?.map(
      (address) =>
        ({
          '@type': 'PostalAddress',
          addressCountry: address.country,
          addressLocality: address.locality,
          addressRegion: address.region,
          postOfficeBoxNumber: address.postOfficeBoxNumber,
          postalCode: address.postalCode,
          streetAddress: address.streetAddress,
        } satisfies PostalAddressSchema)
    ) as PostalAddressSchema[] | undefined

  if (addresses && addresses.length > 0) {
    restaurantSchema.address = addresses
  }

  if (organization?.phone || organization?.email) {
    restaurantSchema.contactPoint = [
      {
        '@type': 'ContactPoint',
        telephone: organization?.phone,
        email: organization?.email,
      },
    ]
  }

  if (organization?.menuPage) {
    const menuPageRelativeUrl = getPageUrl(
      locale,
      organization.menuPage._type,
      organization.menuPage.slug.current
    )
    restaurantSchema.hasMenu = `${siteUrl}${menuPageRelativeUrl}`
  }

  if (organization?.acceptsReservations) {
    restaurantSchema.acceptsReservations = getAcceptsReservationsValue(
      organization.acceptsReservations
    )
  }

  if (organization?.servesCuisine) {
    restaurantSchema.servesCuisine = organization.servesCuisine
  }

  if (organization?.currenciesAccepted) {
    restaurantSchema.currenciesAccepted = organization.currenciesAccepted
  }

  if (organization?.paymentAccepted) {
    restaurantSchema.paymentAccepted = organization.paymentAccepted
  }

  if (organization?.priceRange) {
    restaurantSchema.priceRange = organization.priceRange
  }

  if (organization?.openingHours) {
    restaurantSchema.openingHoursSpecification = organization.openingHours.map(
      ({ dayOfWeek, opens, closes }) => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: dayOfWeekNames[dayOfWeek],
        opens,
        closes,
      })
    )
  }

  return JSON.stringify(restaurantSchema)
}
